import AdminMenuFeature from '@livekatsomo/web/features/admin-menu';
import UserMenuFeature from '@livekatsomo/web/features/user-menu';
import AuthenticationProvider from '@livekatsomo/web/providers/authentication';
import { Breadcrumbs, Footer, Header, MainMenuItems } from '@livekatsomo/web/ui-components/layout';
import { MaxWidthLayoutContainer } from '@livekatsomo/web/ui-components/layouts';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import Image from 'next/image';
import { Suspense } from 'react';
import catAndKeyboard from '../public/cat_keyboard.jpg';
export function PageNotFound() {
  const {
    t
  } = useTranslation();
  return <>
      <Head>
        <title>{t('Page not found')}</title>
      </Head>
      <Header title={`LiveKatsomo`} drawerMenuItems={<>
            <MainMenuItems />
            <AuthenticationProvider>
              <Suspense>
                <AdminMenuFeature />
              </Suspense>
            </AuthenticationProvider>
          </>} userMenu={<AuthenticationProvider>
            <Suspense>
              <UserMenuFeature />
            </Suspense>
          </AuthenticationProvider>} />
      <Breadcrumbs sx={{
      p: 1
    }} />
      <MaxWidthLayoutContainer maxWidth="md" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 4,
      p: 4
    }}>
        <Typography variant="h4" component="h2">
          {t('Whoops! The page you are looking for does not exist.')}
        </Typography>
        <Typography variant="h3" component="p">
          404
        </Typography>

        <Image alt="Cat walking to laptop" src={catAndKeyboard} style={{
        height: 'auto',
        width: '100%',
        // Add rounded corners to the image.
        borderRadius: '10px'
      }} sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" />

        <Typography variant="h6" component="p">
          {t('Maybe your cat decided to take a stroll on your keyboard and accidentally redirected you here. Blame it on the feline overlord!')}
        </Typography>
      </MaxWidthLayoutContainer>
      <Footer />
    </>;
}
export default PageNotFound;