import { User } from '@livekatsomo/models';
import { getOrCreateStore } from '@livekatsomo/suspense';
import { AuthenticationOptions, SigninProcessStatus } from '@livekatsomo/types';
import {
  AuthenticationContext,
  AuthenticationModalType,
} from '@livekatsomo/web/contexts';
import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUser,
  facebookSignIn,
  getSigninMethods,
  googleSignIn,
  isSignInWithEmailLink,
  linkWithProvider,
  logout,
  profileUpdatedCallable,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  reloadUserToken,
  requestDeletionCallable,
  requestEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithProvider,
  tawkToUserHashCallable,
  twitterSignIn,
  updateDisplayName,
  user$,
  verifyPasswordResetCode,
} from '@livekatsomo/web/data';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import { Suspense, useCallback, useRef, useState } from 'react';

export interface AuthenticationProviderProps {
  children: JSX.Element;
}

/**
 * Provides authentication context to its children components.
 *
 * @param children - The child components that will have access to the authentication context.
 */
export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const options: AuthenticationOptions = { authentication: 'firebase' };
  const store = getOrCreateStore<User | null>(options, user$);
  const [error, setError] = useState<Error | null>(null);
  const [processing, setProcessing] = useState<SigninProcessStatus>(null);
  const [providers, setProviders] = useState<string[]>([]);
  const onSuccesfullAuthenticationRef = useRef<(() => void) | null>(null);
  const router = useRouter();
  const [authenticationModalVisible, setModalType] =
    useState<AuthenticationModalType | null>(null);

  const handleShowModal = (
    type: AuthenticationModalType,
    onSuccesfullAuthentication?: null | (() => void),
  ) => {
    setModalType(type);
    if (onSuccesfullAuthentication !== undefined) {
      onSuccesfullAuthenticationRef.current = onSuccesfullAuthentication;
    }
    setError(null);
    setProcessing(null);
  };

  const redirectToLogin = useCallback(() => {
    if (router.pathname !== '/sign-in') {
      router.replace({
        pathname: '/sign-in',
        query: { redirectTo: router.asPath },
      });
    }
  }, [router]);
  const redirectToEmailVerification = useCallback(() => {
    if (router.pathname !== '/email-not-verified') {
      router.replace({
        pathname: '/email-not-verified',
        query: { redirectTo: router.asPath },
      });
    }
  }, [router]);

  const reauthenticate = useCallback(async (email: string) => {
    const providers = await getSigninMethods(email);
    setModalType('reauthenticate');
    setProviders(providers);
  }, []);

  const auth = getAuth();

  return (
    <AuthenticationContext.Provider
      value={{
        auth,
        store,
        authenticationModalVisible,
        error,
        processing,
        providers,
        showModal: handleShowModal,
        logout,
        createUser,
        facebookSignIn,
        googleSignIn,
        twitterSignIn,
        signInWithEmailAndPassword,
        signInAnonymously,
        signInWithEmailLink,
        requestEmailVerification,
        verifyPasswordResetCode,
        confirmPasswordReset,
        checkActionCode,
        applyActionCode,
        sendPasswordResetEmail,
        profileUpdatedCallable,
        requestDeletionCallable,
        getSigninMethods,
        redirectToLogin,
        redirectToEmailVerification,
        isSignInWithEmailLink,
        setProcessing,
        setError,
        updateDisplayName,
        reauthenticate,
        reauthenticateWithCredential,
        reauthenticateWithPopup,
        reloadUser: reloadUserToken,
        linkWithProvider,
        signInWithProvider,
        tawkToUserHashCallable,
        onSuccesfullAuthentication: onSuccesfullAuthenticationRef.current,
      }}
    >
      <Suspense>{children}</Suspense>
    </AuthenticationContext.Provider>
  );
}
